import React from 'react';
import { Link } from 'gatsby';
import Layout from '../layouts/layout';

const AboutPage = () => (
	<Layout title="About Us">
		<div>
			<h1>About Us</h1>

			<p>
				In early 2006, my wife Tammy voiced her desire to attend the
				first-ever Stagecoach Country Music Festival. And because
				tickets weren’t exactly in our budget at the time, I discovered
				a loophole—for less than 1/3 of the cost, we could attend as a
				competing BBQ team! I thought this plan was genius, but Tammy
				looked at me and said, “Ha! When pigs fly!”
			</p>

			<p>
				<em>And so our team was born.</em>
			</p>

			<p>
				We’ve continued on to compete on the BBQ circuit in the “pro”
				division, and have enjoyed much success. We have placed first
				numerous times for our brisket, tri-tip, pulled pork, and pork
				ribs. We’ve also been crowned “Grand Champion” at one of
				California’s largest BBQ contests.
			</p>

			<p>
				Having success and sharing our food with others eventually led
				us to opening a small catering business. As this business began
				to grow, it became apparent we needed a larger workspace—could a
				restaurant be a possibility? (And by this point Tammy had told
				me again and again, “Dale, when pigs fly!”)
			</p>

			<p>
				Wondering if we could really pull it off, we took When Pigs Fly
				BBQ to the 2013 and 2014 Stagecoach Music Festival as vendors,
				and served over 4,500 meals each year during the three-day
				festival. (We were also privileged to cook meals for the Zac
				Brown Band and Darius Rucker!)
			</p>

			<p>
				With the waters tested, we finally felt ready to pursue our
				dream. In April 2014, our need for more space became as reality
				as we broke ground on our first restaurant inside our Chevron
				gas station in Vista, California.
			</p>

			<p>
				Our second location (
				<Link to="/menu-vista-downtown">Downtown Vista</Link>) opened in
				January 2018. We're excited to see what the future holds and
				look forward to including you in the continuing story of When
				Pigs Fly BBQ!
			</p>
		</div>
	</Layout>
);

export default AboutPage;
